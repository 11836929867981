import { Button, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useCreateInvoiceMutation, useGetLastInvoiceQuery } from '../../../api/article.api'
import { useLazyGetResumeQuery } from '../../../api/v2/invoices'
import { useMyContext } from '../../../context/context'
import { useSteps } from '../Steps'
import ResumeCode from './Code'

const Resume = () => {
  const { myContext, settings, t } = useMyContext()
  const { isEnd, articles, userId } = useSteps()
  const [form2] = Form.useForm()

  const [modal, setModal] = useState(false)

  const a = useMemo(() => Object.entries(articles).reduce((prev, [, articleIds]) => [...prev, ...articleIds], []), [articles])
  const [fetchResume, { data: resume }] = useLazyGetResumeQuery()
  const { data: invoice } = useGetLastInvoiceQuery(myContext)
  const [createInvoice, { isLoading }] = useCreateInvoiceMutation()

  useEffect(() => {
    const uId = userId || myContext?.userId
    fetchResume({ ...myContext, articles: a, userId: uId })
  }, [a, fetchResume, myContext, userId])

  useEffect(() => {
    if (invoice && form2) {
      form2.setFieldsValue(invoice)
    }
  }, [form2, invoice])

  if (!resume?.canPay) {
    return null
  }

  return (
    <>
      <Modal
        title="Coordonnées de facturation"
        visible={modal}
        onCancel={() => setModal(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setModal(false)}>Annuler</Button>
            <Button
              type="primary"
              onClick={() => {
                form2.submit()
              }}
              loading={isLoading}
            >
              {t('PAY')}
            </Button>
          </div>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: 600 }}>
            <Form
              form={form2}
              onFinish={async values => {
                const { session } = await createInvoice({
                  ...myContext,
                  body: { referer: window.location.href, bill: values.bill },
                }).unwrap()
                window.location.href = session.url
              }}
            >
              <Form.Item name={['bill', 'type']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Select
                  placeholder="Veuillez sélectionner"
                  options={[
                    { label: 'Société en France (société)', value: 1 },
                    { label: 'Société en Europe (hors France)', value: 2 },
                    { label: 'Société hors Europe', value: 3 },
                    { label: 'Individuel (France ou étranger)', value: 4 },
                    { label: 'Collectivité Territoriale', value: 5 },
                    { label: 'Etablissement Public', value: 6 },
                  ]}
                  disabled={invoice}
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const types = getFieldValue(['bill', 'type'])

                  return (
                    [2, 3].includes(types) && (
                      <>
                        <Form.Item
                          name={['bill', 'intra_communautaire']}
                          label="N° TVA INTRA COMMUNAUTAIRE"
                          rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                          preserve={false}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={['bill', 'country']}
                          label="Pays"
                          rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                          preserve={false}
                        >
                          <Input />
                        </Form.Item>
                      </>
                    )
                  )
                }}
              </Form.Item>
              <Form.Item
                label="Nom/Raison Sociale"
                name={['bill', 'social_reason']}
                rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Adresse" name={['bill', 'address']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Complément d'adresse" name={['bill', 'address_more']}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Code postal"
                name={['bill', 'postal_code']}
                rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Ville" name={['bill', 'city']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input />
              </Form.Item>
              <Form.Item style={{ fontSize: 20, fontWeight: 700, marginBottom: -16 }} shouldUpdate>
                {({ getFieldValue }) => {
                  const type = Number(getFieldValue(['bill', 'type']))
                  if ([1, 4, 5, 6].includes(type)) {
                    return `TOTAL : ${resume.totalTTC} € T.T.C`
                  }

                  return `TOTAL : ${resume.totalHT} € H.T.`
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      <div style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'end' }}>
        <div>{t('RESUME_TOTAL_HT', [resume.currHT])}</div>
        {resume.freeHT > 0 && <div>{t('RESUME_ARTICLES_FREE', [-resume.freeHT])}</div>}
        {resume.remiseHT > 0 && <div>{t('RESUME_PROMO_CODE', [-resume.remiseHT])}</div>}
        {resume.prevHT > 0 && <div>{t('RESUME_TOTAL_PAID', [-resume.prevHT])}</div>}
        {/* {resume?.promoCode && <div>Code promo : -{resume.promoCode.price.toFixed(2)} € H.T.</div>} */}
        <div style={{ width: '100%', height: 2, background: 'grey' }} />
        <div>{t('RESUME_TOTAL_HT_TO_PAY', [resume.totalHT])}</div>
        <div style={{ fontWeight: 700 }}>{t('RESUME_TOTAL_TTC', [resume.totalTTC])}</div>
        <ResumeCode resume={resume} />
        {isEnd && resume.canPay && !settings?.FO_MEETING_CONFIRMATION_HIDE_PAY && (
          <Button type="primary" onClick={() => setModal(true)}>
            {settings?.FO_MEETING_ARTICLES_TO_PAY_LABEL || t('PAY')}
          </Button>
        )}
      </div>
    </>
  )
}

export default Resume
