import qs from 'query-string'
import apiV2 from '.'

export const invoicesApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getResume: builder.query({
      query: ({ domainId, eventId, userId, articles }) =>
        `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/resume?${qs.stringify({ articles })}`,
      transformResponse: response => response.data,
      providesTags: ['INVOICES'],
    }),
    showInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoiceId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/${invoiceId}?${qs.stringify({ gayakoa: true })}`,
        method: 'POST',
        responseHandler: async response => {
          const blob = await response.blob()
          return URL.createObjectURL(blob)
        },
      }),
    }),
  }),
})

export const { useGetResumeQuery, useShowInvoiceMutation, useLazyGetResumeQuery } = invoicesApi
