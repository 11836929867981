import { useEffect, useState } from 'react'
import { useMyContext } from '../context/context'

export default function useActive() {
  const [isActive, setIsActive] = useState(true)
  const [lastActivityTime, setLastActivityTime] = useState(Date.now())

  const { settings } = useMyContext()

  const INACTIVITY_TIMEOUT = settings?.FO_MEETING_INACTIVE_POPUP_TIMER ? settings.FO_MEETING_INACTIVE_POPUP_TIMER * 1000 : 30000

  useEffect(() => {
    const handleUserActivity = () => {
      setIsActive(true)
      setLastActivityTime(Date.now())
    }

    window.addEventListener('mousemove', handleUserActivity)
    window.addEventListener('keydown', handleUserActivity)
    window.addEventListener('click', handleUserActivity)
    window.addEventListener('scroll', handleUserActivity)

    const checkInactivity = setInterval(() => {
      if (Date.now() - lastActivityTime > INACTIVITY_TIMEOUT) {
        setIsActive(false)
      }
    }, 1000)

    return () => {
      window.removeEventListener('mousemove', handleUserActivity)
      window.removeEventListener('keydown', handleUserActivity)
      window.removeEventListener('click', handleUserActivity)
      window.removeEventListener('scroll', handleUserActivity)
      clearInterval(checkInactivity)
    }
  }, [INACTIVITY_TIMEOUT, lastActivityTime])

  return { isActive }
}
