import React, { useEffect, useState } from 'react'
import { useMyContext } from '../../../context/context'
import EventUserQuestionResponse from '../../../services/api/EventUserQuestionResponse'
import Button from '../../../shared/form/button'
import Bars from './Bars'
import Gauge from './Gauge'
import Pie from './Pie'

function cutArray(tableau, tailleLot) {
  const lots = []

  for (let i = 0; i < tableau.length; i += tailleLot) {
    const lot = tableau.slice(i, i + tailleLot)
    lots.push(lot)
  }

  return lots
}

const Questions = ({ data, showTotalVotes = false }) => {
  const { settings, t } = useMyContext()
  const [responsesId, setResponsesId] = useState([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState('')

  const { question, eventLive, questionResults, questionResponses, questionVoters } = data
  const totalVotes = questionResults.reduce((prev, current) => prev + Number(current.votes), 0)
  const totalVoters = questionVoters.length

  const responses = questionResponses
    .map(c => {
      const result = questionResults.find(r => r.id === c.id)
      const votes = result?.votes || 0
      return { ...c, votes }
    })
    .sort((a, b) => {
      if (a.votes < b.votes) return 1
      if (a.votes > b.votes) return -1
      return 0
    })
    .filter((f, i) => (question?.nbShowResponses ? i < question.nbShowResponses : true))

  const responsesNotSort = questionResponses
    .map(c => {
      const result = questionResults.find(r => r.id === c.id)
      const votes = result?.votes || 0
      return { ...c, votes }
    })
    .filter((f, i) => (question?.nbShowResponses ? i < question.nbShowResponses : true))

  useEffect(() => {
    if (data.eventLive.activeQuestionId) {
      setError('')
      setIsSuccess(false)
      setResponsesId([])
    }
  }, [data.eventLive.activeQuestionId])

  const voteOrCloseOrResults = () => {
    if (eventLive.showResults) {
      if (question.display === 'gauge') return <Gauge responses={responsesNotSort} totalVotes={totalVotes} />
      if (question.display === 'pie') return <Pie responses={responses} />
      if (question.display === 'bars')
        return <Bars responses={responses} totalVotes={totalVotes} showRightAnswers={question?.showRightAnswers} eventLive={eventLive} />
      return null
    }

    if (question.isClose)
      return (
        <div
          style={{
            textAlign: 'center',
            fontSize: 22,
            background: settings?.bgPrimaryColor,
            padding: '5px 10px',
            marginBottom: 15,
          }}
        >
          Le temps imparti est écoulé, il n’est plus possible de répondre
        </div>
      )

    if (isSuccess)
      return (
        <div
          style={{
            textAlign: 'center',
            fontSize: 22,
            background: settings?.bgPrimaryColor,
            padding: '5px 10px',
          }}
        >
          Votre réponse est prise en compte
        </div>
      )

    return (
      <div>
        {!settings?.FO_MEETING_SMS_IS_ACTIVE && (
          <div
            style={{
              textAlign: 'center',
              marginBottom: 20,
              fontSize: 18,
              color: 'black',
            }}
          >
            {question.nbResponses} réponse(s) possible(s)
          </div>
        )}
        <div style={{ display: 'flex', gap: 16 }}>
          {cutArray(questionResponses, 6).map((a, ai) => (
            <div style={{ width: '100%' }}>
              {a.map((c, ci) => (
                <div
                  key={Math.random()}
                  style={{
                    background: responsesId.find(r => r === c.id) ? settings?.bgPrimaryColor : 'white',
                    color: !responsesId.find(r => r === c.id) ? 'black' : settings?.textPrimaryColor,
                    border: !settings?.FO_MEETING_SMS_IS_ACTIVE ? `2px solid ${settings?.bgPrimaryColor}` : null,
                    padding: '5px 10px',
                    cursor: 'pointer',
                    marginBottom: 20,
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                  onClick={async () => {
                    if (!settings?.FO_MEETING_SMS_IS_ACTIVE) {
                      setResponsesId(prev => {
                        const find = prev.find(p => p === c.id)
                        if (find) return prev.filter(p => p !== c.id)
                        if (responsesId.length < question.nbResponses) return [...prev, c.id]
                        return [...prev.filter((_, i) => i !== 0), c.id]
                      })
                    }
                  }}
                >
                  {c.image && <img style={{ width: 30, height: 30 }} src={c.image} />}
                  {settings?.FO_MEETING_SMS_IS_ACTIVE && (
                    <div
                      style={{
                        minWidth: 30,
                        minHeight: 30,
                        border: `2px solid ${settings?.bgPrimaryColor}`,
                        borderRadius: 50,
                        fontSize: 18,
                        fontWeight: settings?.FO_MEETING_SMS_IS_ACTIVE ? 900 : 600,
                      }}
                    >
                      {ci + 1 + ai * 6}
                    </div>
                  )}
                  <div> {c?.label}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        {error && (
          <article className="message is-danger field">
            <div className="message-body">{error}</div>
          </article>
        )}
        {!settings?.FO_MEETING_SMS_IS_ACTIVE && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              label={t('VALIDATE')}
              onClick={async () => {
                try {
                  await new EventUserQuestionResponse(question.id).create(responsesId)
                  setIsSuccess(true)
                } catch (err) {
                  setError(err.message)
                }
              }}
              disabled={responsesId.length < 1}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          marginBottom: 10,
          fontSize: 28,
          fontWeight: 700,
        }}
      >
        {question?.label?.split('\n').map(m => (
          <div>{m}</div>
        ))}
      </div>
      <div style={{ textAlign: 'center', marginBottom: 20, fontSize: 22 }}>{question.content}</div>

      <div style={{ color: settings?.textPrimaryColor }}>{voteOrCloseOrResults()}</div>

      {showTotalVotes && (
        <div style={{ marginTop: 20, fontSize: 44, fontWeight: 700 }}>
          <div>Nombre de votes : {totalVotes}</div>
        </div>
      )}
    </div>
  )
}

export default Questions
