import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Button, Button as Button2, Form as Form2, Input, Modal, Space, Spin } from 'antd'
import QRCode from 'qrcode'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDownloadBadgeMutation, useGetDownloadBadgeQuery } from '../../../api/api'
import { useGetArticleUsersQuery } from '../../../api/article.api'
import { useGetCompanionsQuery, useRemoveCompanionMutation } from '../../../api/companion.api'
import { useLazyGetConferencesQuery } from '../../../api/conference.api'
import { useSendEmailMutation } from '../../../api/email.api'
import { useSendEmailMutation as useSendEmailMutationV2 } from '../../../api/v2/emails'
import {
  useCreateInviteMutation,
  useGetEventUserQuery,
  useUpdateEventDayUserMutation,
  useUpdateEventUserMutation,
} from '../../../api/eventUser.api'
import { useGetFormQuery } from '../../../api/form.api'
import { useCreateUserMutation } from '../../../api/user.api'
import { useLazyGetResumeQuery } from '../../../api/v2/invoices'
import RecapPDF from '../../../components/RecapPDF/RecapPDF'
import { useMyContext } from '../../../context/context'
import useConf from '../../../hooks/useConf'
import useHistoryCustom from '../../../hooks/useHistoryCustom'
import useTranslation from '../../../hooks/useTranslation'
import useLog from '../../../hooks/useViewLog'
import EmailApi from '../../../services/api/Email.api'
import formatUserExtended from '../../../services/formatUserExtended'
import ROUTES from '../../../services/routes'
import Form from '../../../shared/form/forms'
import { getDate, getDateTime2 } from '../../../utils/date'
import { getCompanionLabel } from '../../../utils/settings'
import Resume from '../resume'
import { useSteps } from '../Steps'
import AddAssociates from './addAssociates'
import Invoices from './Invoices'

const styles = {
  cardContainer: settings => ({
    padding: 10,
    border: `1px solid ${settings.bgPrimaryColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    gap: 12,
  }),
  name: {
    fontWeight: 700,
  },
  email: {
    fontSize: 12,
  },
}

function isSafari() {
  const { userAgent } = navigator
  return userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1
}

function DownloadBadgeSafari({ userId, badgeId }) {
  const [create] = useLog()
  const { myContext, settings } = useMyContext()

  const { data, isLoading } = useGetDownloadBadgeQuery({ ...myContext, userId, badgeId })

  const openNewWindow = () => {
    window.open(data, '_blank')
    create('DOWNLOAD_BADGE')
  }

  return (
    <Button2 type="primary" loading={isLoading} onClick={openNewWindow}>
      {settings?.FO_MEETING_CONFIRMATION_BADGE_LABEL || 'Télécharger le badge'}
    </Button2>
  )
}

const Companion = ({ user, isCompanion, resume }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { myContext, settings, t } = useMyContext()
  const { resetSteps, participation, isClosed } = useSteps()
  const { badgeId } = useConf(participation)
  const translation = useTranslation()

  const [removeCompanion] = useRemoveCompanionMutation()
  const [sendEmail, { isLoading: isSending, isSuccess: isSendSuccess }] = useSendEmailMutation()
  const [sendEmailV2, { isLoading: isSendingV2, isSuccess: isSendSuccessV2 }] = useSendEmailMutationV2()
  const { data } = useGetArticleUsersQuery({ ...myContext, userId: user.id })
  const [downloadBadgeV2] = useDownloadBadgeMutation()

  const [create] = useLog()

  const backgroundColor = useMemo(() => {
    if (isCompanion) {
      return settings.FO_MEETING_CONFIRMATION_COMPANION_BACKGROUND_COLOR || 'white'
    }
    return settings.FO_MEETING_CONFIRMATION_BACKGROUND_COLOR || 'white'
  }, [isCompanion, settings])

  const color = useMemo(() => {
    if (isCompanion) {
      return settings.FO_MEETING_CONFIRMATION_COMPANION_TEXT_COLOR || 'black'
    }
    return settings.FO_MEETING_CONFIRMATION_TEXT_COLOR || 'black'
  }, [isCompanion, settings])

  const extended = formatUserExtended(translation, user, user?.UserEvent)

  const openNewWindow = async () => {
    setIsLoading(true)
    setIsSuccess(false)
    create('DOWNLOAD_BADGE')
    await downloadBadgeV2({ ...myContext, userId: user.id, badgeId: Number(badgeId) })
      .unwrap()
      .then(url => window.open(url, '_blank'))
    // downloadBadge(user.id, Number(badgeId))
    setIsLoading(false)
    setIsSuccess(true)
  }

  return (
    <>
      <div style={{ ...styles.cardContainer(settings), backgroundColor, color, marginBottom: 4 }}>
        <div>
          <Space>
            <div style={styles.name}>
              {user.firstName} {user.lastName}
            </div>
            {/* {isCompanion && <div>({getCompanionLabel(settings, true)})</div>} */}
          </Space>
          <div style={styles.email}>{user.Authentication?.email}</div>
          <div style={{ fontSize: 14 }}>
            {settings?.FO_MEETING_CONFIRMATION_RECAP_EXTENDED &&
              settings?.FO_MEETING_CONFIRMATION_RECAP_EXTENDED.split(',').map(
                m =>
                  extended[m] && (
                    <div>
                      {extended[m]?.label} : {extended[m]?.value}
                    </div>
                  )
              )}
            {user.conferences?.map(m => (
              <div>{`${m.title} - ${getDateTime2(m.slot.startAt, m.slot.endAt)}`}</div>
            ))}
            {data?.details?.map(m => (
              <>
                <div style={{ fontWeight: 700 }}>{m.day ? getDate(m.day.day) : t('DETAILS')}</div>
                {m.articles.map(a => (
                  <div>- {`${a.label} (${a.price} € H.T.)`}</div>
                ))}
              </>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
          {user.Authentication?.email &&
            settings?.FO_MEETING_CONFIRMATION_COMPANION_TEMPLATE_ID &&
            !user.UserEvent?.isRemote &&
            !resume?.canPay && (
              <Button2
                type="primary"
                loading={isSending || isSendingV2}
                onClick={() => {
                  if (settings?.FO_MEETING_EMAIL_SEND_BADGE) {
                    sendEmailV2({
                      ...myContext,
                      templateId: settings.FO_MEETING_EMAIL_SEND_BADGE,
                      userId: user.id,
                    })
                  } else {
                    sendEmail({
                      ...myContext,
                      accountId: settings.FO_MEETING_CONFIRMATION_COMPANION_ACCOUNT_ID,
                      templateId: settings.FO_MEETING_CONFIRMATION_COMPANION_TEMPLATE_ID,
                      usersId: [user.id],
                    })
                  }
                }}
                disabled={isSendSuccess || isSendSuccessV2}
              >
                Envoyer le badge
              </Button2>
            )}
          {badgeId &&
            !settings?.hideDownloadBadgeAfterRegister &&
            (isCompanion ? true : user?.UserEvent?.UserEventDays?.find(f => f.registered) && !user.UserEvent?.isRemote) &&
            !resume?.canPay && (
              <>
                {isSafari() ? (
                  <DownloadBadgeSafari userId={user.id} badgeId={Number(badgeId)} />
                ) : (
                  <Button2 type="primary" loading={isLoading} onClick={openNewWindow} disabled={isSuccess}>
                    {settings?.FO_MEETING_CONFIRMATION_BADGE_LABEL || 'Télécharger le badge'}
                  </Button2>
                )}
              </>
            )}
          {(!settings?.FO_MEETING_COMPANIONS_AUTO_IS_ACTIVE || !isCompanion) && !isClosed && !user.UserEvent?.representedBy && (
            <Button2 onClick={() => (isCompanion ? resetSteps(5, user.id) : resetSteps())}>{t('EDIT')}</Button2>
          )}
          {isCompanion && !isClosed && (
            <Button2
              type="primary"
              style={{ backgroundColor: 'black', borderColor: 'black' }}
              onClick={() => {
                if (settings?.FO_MEETING_COMPANIONS_REMOVE_HTML) {
                  Modal.confirm({
                    title: <div dangerouslySetInnerHTML={{ __html: settings?.FO_MEETING_COMPANIONS_REMOVE_HTML.html }} />,
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Oui',
                    okType: 'danger',
                    cancelText: 'Non',
                    onOk() {
                      removeCompanion({ ...myContext, userId: user.id })
                    },
                  })
                } else {
                  removeCompanion({ ...myContext, userId: user.id })
                }
              }}
            >
              {t('REMOVE')}
            </Button2>
          )}
        </div>
      </div>
    </>
  )
}

const Confirmation = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const [companions, setCompanions] = useState([])
  const [me, setMe] = useState()
  const [ref, setRef] = useState()
  const [ref2, setRef2] = useState()
  const [companionLoading, setCompanionLoading] = useState(false)
  const [showCompanionsModal, setShowCompanionsModal] = useState(false)
  const [showInvitations, setShowInvitations] = useState(false)
  const [isSend, setIsSend] = useState(false)
  const [showAssociates, setShowAssociates] = useState(false)

  const history = useHistoryCustom()
  const history2 = useHistory()
  const { settings, event, myContext, t } = useMyContext()
  const { participation, userId, resetSteps, isClosed, screenWidth } = useSteps()
  const {
    showCompanions,
    companionsMax,
    formIdUser,
    articlesActive,
    confirmationTemplateId,
    confirmationAccountId,
    showInfo2,
    confOr,
  } = useConf(participation)
  const [companionForm] = Form2.useForm()
  const [invitationForm] = Form2.useForm()
  const translation = useTranslation()

  const { data: meData, isLoading: isLoading1 } = useGetEventUserQuery(myContext)
  const { data, isLoading: isLoading2 } = useGetCompanionsQuery(myContext)
  const [getConferences] = useLazyGetConferencesQuery()
  const [createUser] = useCreateUserMutation()
  const [updateEventUser] = useUpdateEventUserMutation()
  const [updateEventDay] = useUpdateEventDayUserMutation()
  const [createInvite, { isLoading: isInviting }] = useCreateInviteMutation()
  const { data: eventUser, isLoading: isLoading3 } = useGetEventUserQuery({ ...myContext })
  const { data: form, isLoading: isLoading4 } = useGetFormQuery({ ...myContext, formId: formIdUser })
  const { data: form2 } = useGetFormQuery({ ...myContext, formId: settings?.FO_MEETING_INFO_2_FORM_ID })
  const [fetchResume, { data: resume }] = useLazyGetResumeQuery()
  const [sendEmail] = useSendEmailMutationV2()

  const isLoading = useMemo(() => isLoading1 || isLoading2 || isLoading3 || isLoading4, [isLoading1, isLoading2, isLoading3, isLoading4])

  useLog('VIEW_CONFIRMATION')

  const isPresent = participation === 1 || participation === 4

  useEffect(() => {
    const uId = userId || myContext?.userId
    fetchResume({ ...myContext, userId: uId })
  }, [fetchResume, myContext, userId])

  const isRegistered = useMemo(() => {
    let bool = meData?.UserEvent?.registered || meData?.UserEvent?.representedBy
    if (showInfo2 && form2 && !meData?.UserEvent?.representedBy) {
      console.log('form2', ref2?.checkValidity())
      bool = bool && ref2?.checkValidity()
    }
    if (settings?.FO_MEETING_INFO_IS_ACTIVE && form && !meData?.UserEvent?.representedBy) {
      console.log('form', ref?.checkValidity())
      bool = bool && ref?.checkValidity()
    }
    return bool
  }, [meData, showInfo2, form2, settings, form, ref2, ref])

  const sendEmailCb = useCallback(async () => {
    const emailApi = new EmailApi()
    if (!meData) {
      return null
    }

    if (participation === 1) {
      if (settings?.FO_MEETING_EMAIL_CONFIRMATION_REMOTE) {
        return sendEmail({ ...myContext, userId: meData.id, templateId: settings.FO_MEETING_EMAIL_CONFIRMATION_REMOTE }).unwrap()
      }
      if (Number(settings?.FO_MEETING_IS_REMOTE_TEMPLATE_ID)) {
        return emailApi.sendFromTemplateIdAndAccountId(
          Number(settings.FO_MEETING_IS_REMOTE_TEMPLATE_ID),
          Number(settings?.accountId) || 1,
          [meData.id]
        )
      }
    }

    if (participation === 5 && !articlesActive) {
      if (settings?.FO_MEETING_EMAIL_CONFIRMATION) {
        return sendEmail({ ...myContext, userId, templateId: settings.FO_MEETING_EMAIL_CONFIRMATION }).unwrap()
      }
      return emailApi.sendFromActionId(10, [userId], null)
    }

    if (isPresent) {
      if (confOr('FO_MEETING_EMAIL_CONFIRMATION')) {
        return sendEmail({ ...myContext, userId: meData.id, templateId: confOr('FO_MEETING_EMAIL_CONFIRMATION') }).unwrap()
      }
      // pour les typos
      if (confirmationAccountId && confirmationTemplateId) {
        return emailApi.sendFromTemplateIdAndAccountId(Number(confirmationTemplateId), Number(confirmationAccountId) || 1, [meData.id])
      }
      return emailApi.sendFromActionId(10, [meData.id], null)
    }

    const fromUser =
      meData.firstName || meData.lastName
        ? `${meData.firstName || ''} ${meData.lastName || ''} ${meData.Authentication?.email ? `(${meData.Authentication?.email})` : ''}`
        : meData.Authentication?.email || ''

    if (participation === 2) {
      if (settings?.FO_MEETING_EMAIL_REPRESENTED) {
        return sendEmail({ ...myContext, userId, templateId: settings.FO_MEETING_EMAIL_REPRESENTED, variables: { fromUser } }).unwrap()
      }
      if (Number(settings.templateIdRepresented)) {
        return emailApi.sendFromTemplateIdAndAccountId(Number(settings.templateIdRepresented), Number(settings?.accountId) || 1, [userId], {
          fromUser,
        })
      }
    }

    if (participation === 3) {
      if (settings?.FO_MEETING_EMAIL_NO) {
        return sendEmail({ ...myContext, userId: meData.id, templateId: settings.FO_MEETING_EMAIL_NO }).unwrap()
      }
      if (Number(settings.templateIdNo)) {
        return emailApi.sendFromTemplateIdAndAccountId(Number(settings.templateIdNo), Number(settings?.accountId) || 1, [meData.id])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (meData && settings && !isSend && !resume?.canPay) {
      setIsSend(true)
      sendEmailCb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData, settings, isSend, resume])

  useEffect(() => {
    const ok = query.get('status') === 'success'
    if (ok && meData && settings) {
      if (settings?.FO_MEETING_EMAIL_SHOP_SUCCESS) {
        sendEmail({ ...myContext, userId: meData.id, templateId: settings.FO_MEETING_EMAIL_SHOP_SUCCESS }).unwrap()
      } else if (Number(settings.FO_MEETING_ARTICLES_TEMPLATE_ID)) {
        new EmailApi().sendFromTemplateIdAndAccountId(Number(settings.FO_MEETING_ARTICLES_TEMPLATE_ID), Number(settings?.accountId) || 1, [
          meData.id,
        ])
      }

      history2.push({ search: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData])

  const getConfirmationMessage = useCallback(() => {
    if (!me || !settings) {
      return null
    }

    if (isClosed) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_REGISTER_CLOSED?.html }} />
    }

    if (resume?.canPay && articlesActive) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_ARTICLES_TO_PAY_HTML?.html }} />
    }

    if (!isRegistered) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_NOT_PRESENT_HTML?.html }} />
    }

    if (me?.UserEvent?.representedBy && settings.FO_MEETING_CONFIRMATION_REPRESENTED_HTML) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_REPRESENTED_HTML.html }} />
    }

    if (me?.UserEvent?.registered && me?.UserEvent?.isRemote && settings.FO_MEETING_CONFIRMATION_REMOTE_HTML) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_REMOTE_HTML.html }} />
    }

    if (me?.UserEvent?.registered && showCompanions && settings.FO_MEETING_CONFIRMATION_COMPANIONS_HTML) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_COMPANIONS_HTML.html }} />
    }

    if (me?.UserEvent?.registered && settings.FO_MEETING_CONFIRMATION_PRESENT_HTML) {
      return <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFIRMATION_PRESENT_HTML.html }} />
    }

    return (
      <article className="message is-success field" style={{ textAlign: 'center' }}>
        <div className="message-body">
          <strong>Votre inscription a bien été prise en compte.</strong>
          <div>Vous recevrez une confirmation par mail dans quelques minutes.</div>
          <div>(Pensez à vérifier vos spams)</div>
        </div>
      </article>
    )
  }, [articlesActive, isClosed, isRegistered, me, resume, settings, showCompanions])

  useEffect(() => {
    if (data) {
      Promise.all(
        data.users.map(async m => {
          const c = await getConferences({ ...myContext, userId: m.id }).unwrap()
          const qrcode = await QRCode.toDataURL(meData?.UserEvent?.code || '123')
          return { ...m, conferences: c, qrcode }
        })
      ).then(setCompanions)
    }
  }, [data, getConferences, meData, myContext])

  useEffect(() => {
    if (meData) {
      getConferences({ ...myContext, userId: meData.id })
        .unwrap()
        .then(async c => {
          const qrcode = await QRCode.toDataURL(meData?.UserEvent?.code || '123')
          setMe({ ...meData, conferences: c, qrcode })
        })
    }
  }, [getConferences, meData, myContext])

  const createCompanion = async (values = {}) => {
    try {
      setCompanionLoading(true)

      const u = await createUser({
        ...myContext,
        user: {
          lastName: values?.lastName || settings?.FO_MEETING_COMPANIONS_AUTO_LASTNAME || 'Nom',
          firstName: values?.firstName,
        },
      }).unwrap()

      await updateEventUser({
        ...myContext,
        userId: u.id,
        eventUser: {
          registeredBy: `${meData.firstName || ''} ${meData.lastName || ''} (${meData.id})`,
          registeredBy2: meData.id,
          registeredFrom: localStorage.getItem('canal') || 'WEB',
          isRemote: false,
          extended: settings?.FO_MEETING_COMPANIONS_COPY_EXTENDED ? meData?.UserEvent?.extended || {} : {},
          typo: meData?.UserEvent?.typo,
          typoId: meData?.UserEvent?.typoId,
        },
      })
      const promises = [
        ...event.days.map(ed => {
          return updateEventDay({
            ...myContext,
            userId: u.id,
            dayId: ed.id,
            day: { registered: true, registeredAt: new Date() },
          }).unwrap()
        }),
      ]
      await Promise.all(promises)
    } finally {
      setCompanionLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: screenWidth < 800 ? 'column' : 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 16,
          gap: 16,
        }}
      >
        <div style={{ flexGrow: 1 }}>{getConfirmationMessage()}</div>
        <Resume />
      </div>

      <div style={{ width: '100%', maxWidth: 800, gap: 32, display: 'flex', flexDirection: 'column' }}>
        {isRegistered && me && <Companion user={me} resume={resume} />}
        {!isRegistered && !isClosed && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" size="large" onClick={() => resetSteps()}>
              Je m'inscris
            </Button>
          </div>
        )}

        {companions.length > 0 && (
          <div>
            <div style={{ width: '100%', color: 'black', marginBottom: 8, fontWeight: 500 }}>
              Vous avez inscrit{' '}
              <span style={{ color: settings.bgPrimaryColor }}>
                {companions.length} {getCompanionLabel(settings)}
              </span>
            </div>
            {companions.map(u => (
              <Companion key={`companion-${u.id}`} user={u} isCompanion resume={resume} />
            ))}
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 8 }}>
          <div style={{ display: 'flex', gap: 8 }}>
            {showCompanions && isRegistered && !meData.UserEvent?.isRemote && !meData.UserEvent?.representedBy ? (
              <>
                <AddAssociates show={showAssociates} setShow={setShowAssociates} />
                <Modal
                  title={t('REGISTER_COMPANION')}
                  visible={showCompanionsModal}
                  onCancel={() => setShowCompanionsModal(false)}
                  footer={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button onClick={() => setShowCompanionsModal(false)}>Annuler</Button>
                      <Button type="primary" onClick={() => companionForm.submit()} loading={companionLoading}>
                        Ajouter
                      </Button>
                    </div>
                  }
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '100%', maxWidth: 600 }}>
                      <Form2
                        form={companionForm}
                        onFinish={async values => {
                          await createCompanion(values)
                          setShowCompanionsModal(false)
                          companionForm.resetFields()
                        }}
                      >
                        <Form2.Item label="Prénom" name="firstName" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                          <Input />
                        </Form2.Item>
                        <Form2.Item label="Nom" name="lastName" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                          <Input />
                        </Form2.Item>
                      </Form2>
                    </div>
                  </div>
                </Modal>
                {!isClosed && (
                  <Button2
                    type="primary"
                    onClick={async () => {
                      if (settings?.FO_MEETING_COMPANIONS_ASSOCIATES_IS_ACTIVE) {
                        setShowAssociates(true)
                      } else if (settings?.FO_MEETING_COMPANIONS_AUTO_IS_ACTIVE) {
                        await createCompanion()
                      } else if (settings?.FO_MEETING_COMPANIONS_AUTO_NAMES_IS_ACTIVE) {
                        setShowCompanionsModal(true)
                      } else {
                        resetSteps(5, null)
                      }
                    }}
                    disabled={companions.length >= companionsMax}
                  >
                    {t('REGISTER_COMPANION')}
                  </Button2>
                )}

                {settings?.FO_MEETING_ACTIVE_INVITE_USER && (
                  <>
                    <Button2
                      type="primary"
                      onClick={async () => {
                        setShowInvitations(true)
                      }}
                    >
                      Inviter une personne à l'événement
                    </Button2>
                    <Modal
                      title={`Inviter une personne à l'événement`}
                      visible={showInvitations}
                      onCancel={() => setShowInvitations(false)}
                      footer={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button onClick={() => setShowInvitations(false)}>Annuler</Button>
                          <Button type="primary" onClick={() => invitationForm.submit()} loading={isInviting}>
                            Inviter
                          </Button>
                        </div>
                      }
                    >
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '100%', maxWidth: 600 }}>
                          <Form2
                            form={invitationForm}
                            onFinish={async values => {
                              await createInvite({ ...myContext, user: values })
                              setShowInvitations(false)
                              invitationForm.resetFields()
                            }}
                          >
                            <Form2.Item
                              label="Email"
                              name="email"
                              rules={[
                                { required: true, message: 'Ce champ est obligatoire' },
                                { type: 'email', message: "Le format de l'email est incorrect" },
                              ]}
                            >
                              <Input />
                            </Form2.Item>
                            <Form2.Item label="Prénom" name="firstName" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                              <Input />
                            </Form2.Item>
                            <Form2.Item label="Nom" name="lastName" rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                              <Input />
                            </Form2.Item>
                          </Form2>
                        </div>
                      </div>
                    </Modal>
                  </>
                )}
              </>
            ) : (
              <div />
            )}
          </div>
          <Space style={{ flexWrap: 'wrap' }}>
            {settings?.FO_PARTICIPATIONS_PDF_IS_ACTIVE && isRegistered && event && companions && me && !resume?.canPay && (
              <PDFDownloadLink
                style={{ width: '100%' }}
                document={<RecapPDF event={event} settings={settings} me={me} companions={companions} t={translation} />}
                fileName={`Récapitulatif_${me.firstName || ''}-${me.lastName || ''}.pdf`}
              >
                {({ loading }) => (
                  <Button2 style={{ width: '100%' }} type="primary" loading={loading}>
                    {settings?.FO_PARTICIPATIONS_PDF_LABEL || 'Télécharger le récapitulatif'}
                  </Button2>
                )}
              </PDFDownloadLink>
            )}
            {isPresent && settings?.startAt && new Date(settings.startAt) < new Date() && !settings.FO_MEETING_ACCESS_LIVE_BUTTON_HIDE && (
              <Button2
                type="primary"
                onClick={() => {
                  history.push(ROUTES.LIVE)
                }}
              >
                Accéder au live
              </Button2>
            )}

            {settings?.FO_MEETING_CONFIRMATION_BACK_HOME && (
              <Button2
                type="primary"
                onClick={() => {
                  history.push('')
                }}
              >
                Retour accueil
              </Button2>
            )}
          </Space>
        </div>
        <Invoices />
      </div>

      <div style={{ display: 'none' }}>
        {form && <Form user={eventUser} form={form} setRef={setRef} />}
        {form2 && <Form user={eventUser} form={form2} setRef={setRef2} />}
      </div>
    </div>
  )
}

export default Confirmation
