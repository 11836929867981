import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Table } from 'antd'
import React, { useEffect } from 'react'
import { useUpdateEventUserMutation } from '../../../api/eventUser.api'
import { useLazyGetMyAssociatesQuery } from '../../../api/v2/users'
import { useMyContext } from '../../../context/context'
import { useSteps } from '../Steps'

export default function AddAssociates({ show, setShow }) {
  const { myContext, user, t } = useMyContext()
  const { resetSteps } = useSteps()

  const [updateParticipation] = useUpdateEventUserMutation()
  const [fetchMyAssociates, { data }] = useLazyGetMyAssociatesQuery()

  useEffect(() => {
    if (show) {
      fetchMyAssociates(myContext, false)
    }
  }, [fetchMyAssociates, myContext, show])

  return (
    <Modal
      title={t('REGISTER_COMPANION')}
      visible={show}
      onCancel={() => setShow(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setShow(false)}>Annuler</Button>
          <Button type="primary" onClick={() => resetSteps(5, null)}>
            Créer
          </Button>
        </div>
      }
    >
      <div>
        Veuillez choisir dans la liste ci-dessous. Si vous ne trouvez pas la personne que vous souhaitez inscrire, cliquez sur « Créer » en
        bas de la liste.
      </div>
      <div style={{ height: 16 }} />
      <Table
        pagination={false}
        size="small"
        dataSource={data}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName' },
          { title: 'Nom', dataIndex: 'lastName' },
          { title: 'Société', dataIndex: 'name' },
          {
            title: 'Inscrit',
            dataIndex: 'isRegistered',
            render: isRegistered => {
              if (!isRegistered) {
                return (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  </div>
                )
              }

              return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CheckCircleOutlined style={{ color: 'green' }} />
                </div>
              )
            },
          },
          {
            render: v => (
              <Button
                type="primary"
                onClick={async () => {
                  await updateParticipation({ ...myContext, userId: v.id, eventUser: { registeredBy2: user.id } }).unwrap()
                  resetSteps(5, v.id)
                }}
                size="small"
                disabled={v.isRegistered}
              >
                Choisir
              </Button>
            ),
          },
        ]}
      />
    </Modal>
  )
}
