import React, { FormEvent, useState } from 'react'

import Success from '../shared/success'
import ROUTES from '../services/routes'
import useHistoryCustom from '../hooks/useHistoryCustom'
import EmailApi from '../services/api/Email.api'
import MessageError from '../shared/message.error'
import Button from '../shared/form/button'
import Input from '../shared/form/input'
import { useSendEmailMutation } from '../api/v2/emails'
import { useMyContext } from '../context/context'

const PasswordForgot = () => {
  const history = useHistoryCustom()
  const { myContext, settings } = useMyContext() as any

  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<''>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [sendEmail] = useSendEmailMutation()

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (!email) return
    setLoading(true)
    setError('')
    setSuccess(false)

    try {
      if (settings?.FO_MEETING_EMAIL_FORGOT_PASSWORD) {
        await sendEmail({ ...myContext, userId: null, email, templateId: settings.FO_MEETING_EMAIL_FORGOT_PASSWORD }).unwrap()
      } else {
        await new EmailApi().sendFromActionIdAndEmail(3, null, email)
      }
      setSuccess(true)
      setEmail('')
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ padding: '20px 0' }} className="form">
      <form onSubmit={onSubmit} className="field">
        <Input value={email} onChange={setEmail} type="email" label="Email" required focus isForm />
        <MessageError message={error} />
        <Success isSuccess={success} message="Vous allez recevoir un email pour créer un nouveau mot de passe" />
        <Button style={{ width: '100%' }} label="Réinitialiser" loading={loading} submit />
      </form>

      <div className="is-divider" data-content="J'ai retrouvé mon mot de passe" />
      <Button style={{ width: '100%' }} label="Me connecter" onClick={() => history.push(ROUTES.LOGIN)} />
    </div>
  )
}

export default PasswordForgot
