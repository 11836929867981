import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

let baseUrl = 'https://13utcz4xr0.execute-api.eu-west-1.amazonaws.com'
const host = window.location.hostname
if (host === 'localhost') baseUrl = 'http://localhost:5500'
if (host.includes('dev') || host.includes('d1cwsl2gmmbvf6.cloudfront.net')) {
  baseUrl = 'https://2cqh93l668.execute-api.eu-west-1.amazonaws.com'
}

const apiV2 = createApi({
  reducerPath: 'api-v2',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token')
      if (token) headers.set('authorization', `Bearer ${token}`)
      return headers
    },
  }),
  tagTypes: ['SECTION', 'TYPO', 'USER', 'PARTICIPATION'],
  endpoints: builder => ({}),
})

export default apiV2
