import { CalendarOutlined, EnvironmentOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { Button } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMyContext } from '../context/context'

const Footer = () => {
  const location = useLocation()
  const { settings, event, t } = useMyContext()

  if (!event || !settings || (!settings.htmlAssistance && !settings.footerImg) || location.pathname.includes('live')) {
    return <div />
  }

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 25, margin: '0 20px' }}>
        <div
          style={{
            padding: 16,
            backgroundColor: '#F5F6F5',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <InfoCircleOutlined style={{ color: settings?.bgPrimaryColor, fontSize: 22 }} />
          <div style={{ fontSize: 14, fontWeight: 700 }}>{settings?.FO_ORGANIZER_COMPANY_LABEL || "Organisateur de l'événement"}</div>
          <div style={{ fontSize: 13, fontWeight: 600 }}>{settings?.FO_ORGANIZER_COMPANY}</div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 13 }}>{settings?.FO_ORGANIZER_ADDRESS}</div>
            <div style={{ fontSize: 13 }}>{settings?.FO_ORGANIZER_CITY}</div>
          </div>
        </div>
        <div
          style={{
            padding: 16,
            backgroundColor: '#F5F6F5',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <CalendarOutlined style={{ color: settings?.bgPrimaryColor, fontSize: 22 }} />
          <div style={{ fontSize: 14, fontWeight: 700 }}>{settings?.FO_EVENT_DATE_LABEL || "Date de l'événement"}</div>
          <div style={{ fontSize: 13, textAlign: 'center' }}>
            {settings?.FO_EVENT_DATE_CONTENT?.split('\n').map(m => (
              <div>{m}</div>
            ))}
          </div>
          {settings.FO_EVENT_DATE_START && settings.FO_EVENT_DATE_END && !settings.FO_EVENT_DATE_HIDE && (
            <AddToCalendarButton
              name={event.name}
              startDate={dayjs(settings.FO_EVENT_DATE_START).format('YYYY-MM-DD')}
              startTime={dayjs(settings.FO_EVENT_DATE_START).format('HH:mm')}
              endDate={dayjs(settings.FO_EVENT_DATE_END).format('YYYY-MM-DD')}
              endTime={dayjs(settings.FO_EVENT_DATE_END).format('HH:mm')}
              options={['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo']}
              label={t('ADD_TO_CALENDAR')}
              timeZone="Europe/Paris"
              location={`${settings.FO_ORGANIZER_ADDRESS} - ${settings.FO_ORGANIZER_CITY}`}
              trigger="click"
            />
          )}
          {/* {settings?.FO_MEETING_ICS_FILE && (
            <Button
              type="primary"
              onClick={async () => {
                const response = await fetch(
                  `https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings?.FO_MEETING_ICS_FILE}`,
                  { cache: 'no-cache' }
                )
                fileDownload(await response.blob(), `evenement.ics`)
              }}
            >
              Ajouter à mon calendrier
            </Button>
          )} */}
        </div>
        <div
          style={{
            padding: 16,
            backgroundColor: '#F5F6F5',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <EnvironmentOutlined style={{ color: settings?.bgPrimaryColor, fontSize: 22 }} />
          <div style={{ fontSize: 14, fontWeight: 700 }}>{settings?.FO_EVENT_ADDRESS_LABEL || "Localisation de l'événement"}</div>
          <div style={{ fontSize: 13, textAlign: 'center' }}>
            {settings?.FO_EVENT_ADDRESS?.split('\n').map(m => (
              <div>{m}</div>
            ))}
          </div>
          {!settings?.FO_EVENT_ADDRESS_GOOGLE_MAPS_HIDE && (
            <Button
              type="primary"
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${settings?.FO_EVENT_LOCATION}`, '_blank')}
            >
              {t('ITINERARY_GOOGLE_MAPS')}
            </Button>
          )}
          {!settings?.FO_EVENT_ADDRESS_WAZE_HIDE && (
            <Button
              type="primary"
              onClick={() => window.open(`https://www.waze.com/fr/live-map/directions?to=${settings?.FO_EVENT_LOCATION}`, '_blank')}
            >
              {t('ITINERARY_WAZE')}
            </Button>
          )}
        </div>
      </div>
      {settings.htmlAssistance && (
        <div
          style={{ padding: 20, backgroundColor: settings?.bgColorAssistance }}
          dangerouslySetInnerHTML={{ __html: settings.htmlAssistance.html || settings.htmlAssistance }}
        />
      )}
      {settings.footerImg && (
        <img
          alt="footer"
          src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.footerImg}`}
          style={{ width: '100%' }}
        />
      )}
      {settings.FO_MEETING_MENTIONS_HTML && (
        <div
          style={{ padding: 20, backgroundColor: settings?.backgroundColor }}
          dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_MENTIONS_HTML.html }}
        />
      )}
    </div>
  )
}

export default Footer
