import { Alert, Button, Form, Input, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { useMyContext } from '../context/context'

const useQrReader = () => {
  const { t } = useMyContext()
  const [form] = Form.useForm()
  const inputRef = React.useRef(null)

  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState(null)
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'scan')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  })

  const persistMode = newMode => {
    setMode(newMode)
    localStorage.setItem('mode', newMode)
  }

  const QrCode = ({ onChange = null }) => {
    const updateCode = async newCode => {
      if (!newCode) return

      setLoading(true)

      const hasUrl = newCode.replace('-', '=').includes('=')
      if (hasUrl) {
        const onlyCode = newCode.replace('-', '=').split('=')[1]
        setCode(onlyCode)
        if (onChange) await onChange(onlyCode)
      } else {
        setCode(newCode)
        if (onChange) await onChange(newCode)
      }

      setLoading(false)
      form.resetFields()

      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    return (
      <div style={{ maxWidth: 'calc(100vh - 300px)', width: '100%' }}>
        <div>
          <Space style={{ marginBottom: 10 }}>
            <Button type={mode === 'scan' ? 'primary' : 'default'} onClick={() => persistMode('scan')}>
              Mode scan
            </Button>
            <Button type={mode === 'manuel' ? 'primary' : 'default'} onClick={() => persistMode('manuel')}>
              Mode manuel
            </Button>
          </Space>
        </div>

        {mode === 'manuel' && (
          <Form form={form} style={{ display: 'flex' }} onFinish={values => updateCode(values.code)}>
            <Form.Item style={{ width: '100%', marginBottom: 0 }} name="code">
              <Input ref={inputRef} placeholder="Entrer le code manuellement" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('VALIDATE')}
            </Button>
          </Form>
        )}

        {mode === 'scan' && (
          <>
            {isError && <Alert type="error" message="Veuillez activer votre caméra et recharger la page" />}
            <QrReader
              delay={500}
              onError={() => setIsError(true)}
              onScan={async newCode => {
                setIsError(false)
                if (newCode && newCode !== code) {
                  updateCode(newCode)
                }
              }}
            />
          </>
        )}

        <div style={{ textAlign: 'center', fontSize: 16 }}>{code}</div>
      </div>
    )
  }

  return [QrCode, { code }]
}

export default useQrReader
