import apiV2 from '.'

export const languagesApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getLanguages: builder.query({
      query: () => '/languages',
      transformResponse: response => response.data.languages,
      providesTags: ['LANGUAGES'],
    }),
  }),
})

export const { useGetLanguagesQuery } = languagesApi
