import { AutoComplete, Button, Form, Select } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useCreateEmployeeMutation, useGetCompaniesQuery } from '../../api/company.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import { indexes, indexesArr } from './indexes'

const CompanySiren = ({ toCreate, toList }) => {
  const [siren, setSiren] = useState('')
  const [country, setCountry] = useState('FR')
  const { domainId, userId, t } = useMyContext()
  const { companyIndexSize, companyIndexLabel, COMPANIES_INDEX_HTML, FO_INDEX_INTER } = useConf()

  const { data: companies } = useGetCompaniesQuery({ domainId, siren, country })
  const [createEmployee] = useCreateEmployeeMutation()

  const index = useMemo(() => indexes[country], [country])

  const onSelect = async (_, { data }) => {
    await createEmployee({ domainId, body: { userId, companyId: data.id } })
    toList(data.id)
  }

  const onCreate = useCallback(
    v => {
      toCreate(v)
    },
    [toCreate]
  )

  return (
    <div>
      <div style={{ height: 16 }} />
      <Button type="primary" onClick={toList}>
        {t('BACK')}
      </Button>
      {COMPANIES_INDEX_HTML ? (
        <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: COMPANIES_INDEX_HTML.html }} />
      ) : (
        <div style={{ height: 16 }} />
      )}
      <Form onFinish={onCreate} layout="vertical">
        {FO_INDEX_INTER ? (
          <>
            <Form.Item
              label={t('SELECT_COUNTRY')}
              name="country"
              rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
              initialValue="FR"
            >
              <Select options={indexesArr.map(m => ({ label: m.country, value: m.value }))} value={country} onChange={setCountry} />
            </Form.Item>
            <Form.Item
              label={`${index.label}`}
              name="siren"
              rules={[
                { pattern: index.pattern, message: 'Mauvais format' },
                { required: true, message: 'Ce champ est obligatoire' },
              ]}
            >
              <AutoComplete
                options={
                  siren ? companies.map(company => ({ label: `${company.name} (${company.siren})`, value: company.id, data: company })) : []
                }
                onSelect={onSelect}
                onSearch={setSiren}
                placeholder={`${t('EXAMPLE')} : ${index.example}`}
              />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label={`${companyIndexLabel || 'SIREN de la société'} (la taille doit être de ${companyIndexSize || 9})`}
            name="siren"
            rules={[
              { len: companyIndexSize || 9, message: `La taille doit être de ${companyIndexSize || 9}` },
              { pattern: new RegExp('^[0-9]+$'), message: 'Le champ doit contenir uniquement des chiffres' },
              { required: true, message: 'Ce champ est obligatoire' },
            ]}
          >
            <AutoComplete
              options={
                siren ? companies.map(company => ({ label: `${company.name} (${company.siren})`, value: company.id, data: company })) : []
              }
              onSelect={onSelect}
              onSearch={setSiren}
            />
          </Form.Item>
        )}
        <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
          <Button type="primary" htmlType="submit">
            {t('NEXT')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default CompanySiren
