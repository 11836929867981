import apiV2 from '.'

export const eventLogsApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    createEventLog: builder.mutation({
      query: ({ domainId, eventId, userId, type }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/logs`,
        method: 'POST',
        body: { type },
      }),
    }),
  }),
})

export const { useCreateEventLogMutation } = eventLogsApi
