/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Modal, Radio } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useGetEventUserQuery, useUpdateEventUserMutation } from '../api/eventUser.api'
import { useMyContext } from '../context/context'
import useConfOffline from '../hooks/useConfOffline'
import useTypos from '../hooks/useTypos'
import ROUTES from '../services/routes'
import { useGetFlagEventQuery } from '../api/v2/participations'

const PrivateRoute = ({ path, ...rest }: any) => {
  const [show, setShow] = useState(false)

  const { url } = useRouteMatch()
  const { user, settings, myContext, user: me, t } = useMyContext() as any
  const { typo } = useConfOffline()
  const [form] = Form.useForm()
  const { push } = useHistory()

  const { data: typosV2, isLoading: isGet } = useTypos()

  const { data } = useGetEventUserQuery(myContext)
  const { data: flag } = useGetFlagEventQuery(myContext)
  const [update, { isLoading }] = useUpdateEventUserMutation()

  const showTypo = useMemo(() => settings && data && me && settings.FO_MEETING_CHOOSE_TYPO && !data.UserEvent?.typoId && !me.isExhibitor, [
    data,
    me,
    settings,
  ])

  useEffect(() => {
    if (settings?.FO_MEETING_CHOOSE_TYPO && !data?.UserEvent?.typoId && !isGet && me.isExhibitor) {
      update({
        ...myContext,
        eventUser: {
          typo: me.isExhibitor && settings?.FO_MEETING_TYPO_DEFAULT_EXHIBITOR ? settings.FO_MEETING_TYPO_DEFAULT_EXHIBITOR : me.typo,
          typoId:
            me.isExhibitor && settings?.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR ? settings.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR : me.typoId,
        },
      })
    }
  }, [data, isGet, me, settings, update, myContext])

  useEffect(() => {
    if (typo && form) {
      form.setFieldsValue({ typoId: `${typo.id}` })
    }
  }, [form, typo])

  useEffect(() => {
    if (flag?.hasParticipation) {
      setShow(true)
    }
  }, [flag])

  if (!user) return <Redirect to={url + ROUTES.LOGIN} />

  return (
    <>
      {showTypo && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form form={form} onFinish={v => update({ ...myContext, eventUser: { typoId: v.typoId === 'null' ? null : v.typoId } })}>
            {settings?.FO_MEETING_TYPO_HTML && (
              <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_TYPO_HTML.html }} />
            )}
            <Form.Item
              name="typoId"
              rules={[{ required: true, message: `${settings?.FO_MEETING_TYPO_LABEL || 'Typologie'} est obligatoire` }]}
            >
              <Radio.Group options={typosV2} style={{ display: 'flex', flexDirection: 'column', gap: 8 }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('VALIDATE')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      {!showTypo && <Route {...rest} />}

      <Modal
        title={settings?.FO_MEETING_REDIRECT_TO_AFTER_LOGIN_TITLE || 'Vous participez déjà à cet événement'}
        visible={show}
        onCancel={() => null}
        footer={null}
      >
        <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              push(`/${flag?.alias}`)
            }}
            type="primary"
          >
            {settings?.FO_MEETING_REDIRECT_TO_AFTER_LOGIN_YES || 'Je veux voir ma participation'}
          </Button>
          <Button
            onClick={() => {
              setShow(false)
            }}
          >
            {settings?.FO_MEETING_REDIRECT_TO_AFTER_LOGIN_NO || 'Non, je veux rester sur cette page'}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default PrivateRoute
