import { Alert, Button } from 'antd'
import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { useMyContext } from '../context/context'
import useHistoryCustom from '../hooks/useHistoryCustom'
import ROUTES from '../services/routes'

const LoginBadge = () => {
  const { event, t } = useMyContext()
  const history = useHistoryCustom()

  const [code, setCode] = useState()
  const [error, setError] = useState()

  return (
    <div>
      <Button style={{ marginTop: 20, marginBottom: 10 }} type="primary" onClick={() => history.push(ROUTES.LOGIN)}>
        {t('BACK')}
      </Button>
      {error && <Alert style={{ marginBottom: 10 }} message={error} type="error" />}
      <QrReader
        delay={500}
        onError={() => setError('Veuillez activer la caméra pour scanner votre badge')}
        onScan={newCode => {
          setError(null)
          if (newCode && newCode !== code) {
            window.location.href = `https://api.gayakoa.com/v2/authentications/connect-with-badge-v2?badge=${newCode}&referer=${window.location.origin}/${event.alias}/live`
            setCode(newCode)
          }
        }}
      />
    </div>
  )
}

export default LoginBadge
