import { Document, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import { getDateTime2 } from '../../utils/date'
import { getApiUrl } from '../../services/fetch'
import { getCompanionLabel } from '../../utils/settings'
import formatUserExtended from '../../services/formatUserExtended'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    padding: 10,
  },
  user: {
    container: {
      border: '1px solid black',
    },
  },
})

const fontSizes = {
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
}

function cutArray(tableau, tailleLot) {
  const lots = []

  for (let i = 0; i < tableau.length; i += tailleLot) {
    const lot = tableau.slice(i, i + tailleLot)
    lots.push(lot)
  }

  return lots
}

const RecapPDF = ({ settings, me, companions, t }) => {
  const User = ({ user, isCompanion }) => {
    const backgroundColor = useMemo(() => {
      if (isCompanion) {
        return settings?.FO_MEETING_CONFIRMATION_COMPANION_BACKGROUND_COLOR || 'white'
      }
      return settings?.FO_MEETING_CONFIRMATION_BACKGROUND_COLOR || 'white'
    }, [isCompanion])

    const color = useMemo(() => {
      if (isCompanion) {
        return settings?.FO_MEETING_CONFIRMATION_COMPANION_TEXT_COLOR || 'black'
      }
      return settings?.FO_MEETING_CONFIRMATION_TEXT_COLOR || 'black'
    }, [isCompanion])

    const extended = formatUserExtended(t, user, user?.UserEvent)

    return (
      <View
        style={{
          border: `1px solid ${settings?.bgPrimaryColor}`,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 8,
          paddingVertical: 4,
          backgroundColor,
          color,
          marginBottom: 4,
        }}
      >
        <View>
          <Text style={{ marginBottom: 2, fontSize: fontSizes.l }}>
            <Text style={{ fontStyle: 'bold' }}>{`${user.firstName || ''} ${user.lastName || ''} `}</Text>
            <Text>{`${isCompanion ? `(${getCompanionLabel(settings, true)})` : ''}`}</Text>
          </Text>
          {user.Authentication && <Text style={{ fontSize: fontSizes.m, marginBottom: 2 }}>{user.Authentication.email}</Text>}
          {settings?.FO_MEETING_CONFIRMATION_RECAP_EXTENDED &&
            settings?.FO_MEETING_CONFIRMATION_RECAP_EXTENDED.split(',').map(
              m =>
                extended[m] && (
                  <Text style={{ fontSize: fontSizes.m, marginBottom: 2 }}>
                    {extended[m]?.label} : {extended[m]?.value}
                  </Text>
                )
            )}
          {user.conferences?.map(m => (
            <Text style={{ fontSize: fontSizes.m, marginBottom: 2 }}>{`${m.title} - ${getDateTime2(m.slot.startAt, m.slot.endAt)}`}</Text>
          ))}
        </View>
        <View>
          {user.qrcode ? (
            <Link src={`${getApiUrl()}/r?c=${user.UserEvent.code}`}>
              <Image style={{ height: 50 }} src={user.qrcode} />
            </Link>
          ) : (
            <Text>QRCODE</Text>
          )}
        </View>
      </View>
    )
  }

  const RecapPage = ({ c }) => {
    return (
      <Page size="A4" style={styles.page}>
        <View style={{ position: 'relative', top: 0 }}>
          {settings?.FO_MEETING_RECAP_PDF_BACKGROUND && (
            <Image
              src={{
                uri: `https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.FO_MEETING_RECAP_PDF_BACKGROUND}`,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: '',
              }}
              style={{
                position: 'absolute',
                height: '100vh',
                width: '100vw',
              }}
            />
          )}
          {settings?.FO_MEETING_RECAP_PDF_MARGIN_TOP && <View style={{ height: settings.FO_MEETING_RECAP_PDF_MARGIN_TOP }} />}
          {settings?.headerImg && !settings?.FO_MEETING_RECAP_PDF_BACKGROUND && (
            <Image
              src={{
                uri: `https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.headerImg}`,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: '',
              }}
            />
          )}
          {settings?.FO_PARTICIPATIONS_PDF_MESSAGE && (
            <View style={{ padding: 16 }}>
              <Text style={{ textAlign: 'center', fontSize: fontSizes.l }}>{settings?.FO_PARTICIPATIONS_PDF_MESSAGE}</Text>
            </View>
          )}

          {settings?.FO_PARTICIPATIONS_PDF_MESSAGE_2 && (
            <View style={{ padding: 16 }}>
              <Text style={{ textAlign: 'center', fontSize: fontSizes.l }}>{settings?.FO_PARTICIPATIONS_PDF_MESSAGE_2}</Text>
            </View>
          )}
        </View>

        <View style={{ marginHorizontal: '5%' }}>
          <View>
            <User user={me} />
          </View>

          {c?.length > 0 && (
            <Text style={{ fontSize: fontSizes.m, marginTop: 16, marginBottom: 4 }}>
              <Text>Vous avez inscrit</Text>{' '}
              <Text style={{ color: settings?.bgPrimaryColor }}>
                {c?.length} {getCompanionLabel(settings)}
              </Text>
            </Text>
          )}
          {c?.map(companion => (
            <User user={companion} isCompanion />
          ))}
        </View>

        <View>
          {settings?.FO_PARTICIPATIONS_PDF_MESSAGE_3 && (
            <View style={{ padding: 16 }}>
              <Text style={{ textAlign: 'center', fontSize: fontSizes.l }}>{settings?.FO_PARTICIPATIONS_PDF_MESSAGE_3}</Text>
            </View>
          )}

          {!settings?.FO_MEETING_RECAP_PDF_BACKGROUND && (
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, margin: 10 }}>
              <View
                style={{
                  padding: 16,
                  backgroundColor: '#F5F6F5',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Text style={{ fontSize: fontSizes.s, fontWeight: 700 }}>
                  {settings?.FO_ORGANIZER_COMPANY_LABEL || "Organisateur de l'événement"}
                </Text>
                <Text style={{ fontSize: fontSizes.xs, fontWeight: 600 }}>{settings?.FO_ORGANIZER_COMPANY}</Text>
                <View style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Text style={{ fontSize: fontSizes.xs, textAlign: 'center' }}>{settings?.FO_ORGANIZER_ADDRESS}</Text>
                  <Text style={{ fontSize: fontSizes.xs, textAlign: 'center' }}>{settings?.FO_ORGANIZER_CITY}</Text>
                </View>
              </View>
              <View
                style={{
                  padding: 16,
                  backgroundColor: '#F5F6F5',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Text style={{ fontSize: fontSizes.s, fontWeight: 700 }}>{settings?.FO_EVENT_DATE_LABEL || "Date de l'événement"}</Text>
                <View style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {settings?.FO_EVENT_DATE_CONTENT?.split('\n').map(m => (
                    <Text style={{ fontSize: fontSizes.xs, textAlign: 'center' }}>{m}</Text>
                  ))}
                </View>
              </View>
              <View
                style={{
                  padding: 16,
                  backgroundColor: '#F5F6F5',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Text style={{ fontSize: fontSizes.s, fontWeight: 700 }}>
                  {settings?.FO_EVENT_ADDRESS_LABEL || "Localisation de l'événement"}
                </Text>
                <View style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {settings?.FO_EVENT_ADDRESS?.split('\n').map(m => (
                    <Text style={{ textAlign: 'center', fontSize: fontSizes.xs }}>{m}</Text>
                  ))}
                </View>
              </View>
            </View>
          )}
          {settings?.footerImg && !settings?.FO_MEETING_RECAP_PDF_BACKGROUND && (
            <Image
              style={styles.footer}
              src={{
                uri: `https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.footerImg}`,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: '',
              }}
            />
          )}
        </View>
      </Page>
    )
  }

  return <Document>{companions?.length > 0 ? cutArray(companions, 6).map(c => <RecapPage c={c} />) : <RecapPage />}</Document>
}

export default RecapPDF
