/* eslint-disable react/no-danger */
import { Alert, Button, Form, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import { useMyContext } from '../../context/context'
import useConfOffline from '../../hooks/useConfOffline'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import useTypos from '../../hooks/useTypos'
import AuthenticationApi from '../../services/api/Authentication.api'
import ROUTES from '../../services/routes'
import { useSendEmailMutation } from '../../api/v2/emails'
import ShowPhotosWithBadge from './ShowPhotosWithBadge'
import { useLazyCheckUserByEmailQuery, useRegisterMutation, useSendForgotPasswordMutation } from './api'
import './index.css'

const styles = {
  container: { display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 10 },
  form: { width: 300, margin: '0 auto' },
}

const Login = () => {
  const history = useHistoryCustom()
  const [form] = Form.useForm()
  const { domainId, eventId, login, user, settings, errorIsExhibitor, myContext } = useMyContext()

  const { LOGIN_HTML, typo } = useConfOffline()

  const { data: typosV2 } = useTypos()

  const [searchUser, { isFetching: isSearching, data: findUser, isSuccess: showNext }] = useLazyCheckUserByEmailQuery()
  const [register, { isLoading: isRegistering, error: registerError }] = useRegisterMutation()
  const [sendForgotPassword, { isLoading: isSending, isSuccess: isSended }] = useSendForgotPasswordMutation()
  const [sendEmail] = useSendEmailMutation()

  const autoConnect = settings?.FO_MEETING_AUTO_CONNECT || false
  // Search email
  const step1 = !showNext || isSearching
  // Email exists
  const step2 = showNext && findUser
  // Email not exists
  const step3 = showNext && !findUser && !settings?.FO_MEETING_CREATE_ACCOUNT_HIDE
  const step3NotOK = showNext && !findUser && settings?.FO_MEETING_CREATE_ACCOUNT_HIDE

  useEffect(() => {
    if (user) {
      history.push(settings?.FO_MEETING_REDIRECT_TO_AFTER_LOGIN || ROUTES.HOME)
    }
  }, [history, settings, user])

  const onSubmit = async values => {
    const { email, password } = values
    let token = null

    if (step2) {
      if (autoConnect) {
        if (settings?.FO_MEETING_EMAIL_FORGOT_PASSWORD) {
          await sendEmail({
            ...myContext,
            userId: null,
            email,
            templateId: settings.FO_MEETING_EMAIL_FORGOT_PASSWORD,
            variables: { referer: window.location.origin + window.location.pathname },
          }).unwrap()
        } else {
          await sendForgotPassword({
            domainId,
            eventId,
            email,
            referer: window.location.origin + window.location.pathname,
          }).unwrap()
        }
      } else {
        const result = await AuthenticationApi.login(email, password, eventId)
        token = result?.token
      }
    } else if (step3) {
      token = await register({ domainId, eventId, user: values }).unwrap()
    } else if (step1 || step3NotOK) {
      const f = await searchUser({ domainId, email }).unwrap()
      if (!f && settings?.FO_MEETING_CREATE_ACCOUNT_HIDE) {
        form.resetFields()
      }
    }

    if (token) {
      login(token)
      history.push(settings?.FO_MEETING_REDIRECT_TO_AFTER_LOGIN || ROUTES.HOME)
    }
  }

  useEffect(() => {
    if (step2) {
      form.submit()
    }
  }, [form, step2])

  useEffect(() => {
    if (typo) {
      form.setFieldsValue({ typoId: `${typo.id}` })
    }
  }, [form, typo])

  return (
    <div style={styles.container}>
      {LOGIN_HTML && <div className="login" style={{ padding: 20, width: '50%' }} dangerouslySetInnerHTML={{ __html: LOGIN_HTML?.html }} />}
      <div
        className="login"
        style={{
          width: '50%',
          // borderTop: `3px solid ${event?.settings?.FO_PRIMARY_COLOR}`,
          // borderBottom: `3px solid ${event?.settings?.FO_PRIMARY_COLOR}`,
          padding: '10px 0',
        }}
      >
        {settings?.FO_MEETING_LOGIN_MESSAGE_1 && step1 && (
          <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_LOGIN_MESSAGE_1.html }} />
        )}
        {settings?.FO_MEETING_LOGIN_MESSAGE_2 && step2 && (
          <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_LOGIN_MESSAGE_2.html }} />
        )}
        {settings?.FO_MEETING_LOGIN_MESSAGE_3 && step3 && (
          <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_LOGIN_MESSAGE_3.html }} />
        )}

        {settings?.FO_MEETING_SHOW_PHOTOS_WITH_BADGE ? (
          <ShowPhotosWithBadge />
        ) : (
          <Form form={form} style={styles.form} layout="vertical" onFinish={onSubmit}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: 'email', required: true, message: "Le format de l'email est incorrect" }]}
            >
              <Input type="email" disabled={isSearching || step2 || step3} />
            </Form.Item>

            {step2 && !autoConnect && (
              <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Le mot de passe est obligatoire' }]}>
                <Input.Password />
              </Form.Item>
            )}

            {step3 && (
              <>
                <Form.Item label="Prénom" name="firstName" rules={[{ required: true, message: 'Le prénom est obligatoire' }]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Nom" name="lastName" rules={[{ required: true, message: 'Le nom est obligatoire' }]}>
                  <Input />
                </Form.Item>

                {settings?.FO_MEETING_CREATE_ACCOUNT_TYPO && (
                  <Form.Item
                    label={settings?.FO_MEETING_TYPO_LABEL || 'Typologie'}
                    name="typoId"
                    rules={[{ required: true, message: `${settings?.FO_MEETING_TYPO_LABEL || 'Typologie'} est obligatoire` }]}
                  >
                    <Select options={typosV2} />
                  </Form.Item>
                )}
              </>
            )}

            {registerError && (
              <Form.Item>
                <Alert message={registerError.data?.message} type="error" showIcon />
              </Form.Item>
            )}

            {errorIsExhibitor && (
              <Form.Item>
                <Alert
                  message="Cet espace d'inscription est réservé aux visiteurs or vous êtes exposant sur cet événement, veuillez vous rapprocher de l'organisation pour télécharger votre badge exposant. Merci de votre compréhension"
                  type="error"
                  showIcon
                />
              </Form.Item>
            )}

            {step3NotOK && (
              <Form.Item>
                <Alert
                  message={
                    settings?.FO_MEETING_LOGIN_MESSAGE_3 ? (
                      <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_LOGIN_MESSAGE_3.html }} />
                    ) : (
                      "Ce compte n'existe pas"
                    )
                  }
                  type="error"
                  showIcon
                />
              </Form.Item>
            )}

            {isSended && (
              <Form.Item>
                <Alert message="Vous allez recevoir un lien par email pour vous connecter" type="success" showIcon />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSearching || isSending || isRegistering}>
                Connexion
              </Button>
            </Form.Item>

            {settings?.FO_MEETING_SHOW_LOGIN_BY_BADGE && (
              <Form.Item>
                <Button type="primary" onClick={() => history.push(ROUTES.LOGIN_WITH_BADGE)}>
                  SE CONNECTER AVEC MON BADGE
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
      </div>
    </div>
  )
}

export default Login
