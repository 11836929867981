import { Alert, Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSetCodeUserMutation } from '../../../api/v2/articleCodes'
import { useMyContext } from '../../../context/context'

export default function ResumeCode({ resume }) {
  const [show, setShow] = useState(false)

  const { settings, myContext, t } = useMyContext()
  const [form] = Form.useForm()

  const [setCodeUser, { isLoading, error }] = useSetCodeUserMutation()

  useEffect(() => {
    if (show && resume?.code?.code && form) {
      form.setFieldsValue({ code: resume.code.code })
    }
  }, [form, resume, show])

  return show ? (
    <>
      <Form
        form={form}
        onFinish={async v => {
          if (resume?.promoCode?.code === v.code) {
            setShow(false)
          }

          setCodeUser({ ...myContext, code: v.code })

          //   const result = await setPromoCode({ ...myContext, ...v }).unwrap()
          //   if (result?.noCode) {
          //     form.setFieldsValue({ code: resume?.promoCode?.code || '' })
          //   } else {
          //     setShow(false)
          //   }
        }}
        style={{ display: 'flex', gap: 8 }}
      >
        <Form.Item name="code" noStyle>
          <Input placeholder={t('PROMO_CODE_PLACEHOLDER')} disabled={resume?.isUsed} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} disabled={resume?.isUsed}>
          {t('PROMO_CODE_BUTTON')}
        </Button>
      </Form>
      {error && <Alert message={t(error?.data?.message || 'Une erreur est survenue')} type="error" />}
    </>
  ) : (
    <div
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
        marginBottom: 8,
        display: settings?.FO_MEETING_CONFIRMATION_HIDE_PAY ? 'none' : 'visible',
      }}
      onClick={() => setShow(true)}
    >
      {t('PROMO_CODE_SHOW')}
    </div>
  )
}
