export const indexes = {
  FR: {
    country: 'France',
    label: 'SIRET de la société',
    value: 'FR',
    pattern: /^\d{14}$/,
    example: '12345678912345',
  },
  IT: {
    country: 'Italia',
    label: 'Numero REA',
    value: 'IT',
    pattern: /^[A-Z]{2}\d{6,9}$/,
    example: 'AB123456',
  },
  CH: {
    country: 'Schweiz',
    label: 'Unternehmens-Identifikationsnummer',
    value: 'CH',
    pattern: /^CHE-\d{3}\.\d{3}\.\d{3}$/,
    example: 'CHE-123.456.789',
  },
  PT: {
    country: 'Portugal',
    label: 'Número de Identificação Fiscal (NIF)',
    value: 'PT',
    pattern: /^\d{9}$/,
    example: '123456789',
  },
  PL: {
    country: 'Polska',
    label: 'Numer REGON',
    value: 'PL',
    pattern: /^\d{9,14}$/,
    example: '123456789',
  },
  ES: {
    country: 'España',
    label: 'Número de Identificación Fiscal (NIF)',
    value: 'ES',
    pattern: /^[XYZ]?\d{7}[A-Z]$/,
    example: 'X1234567L',
  },
}

export const indexesArr = Object.values(indexes)
