import apiV2 from '.'

export const articleCodesApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    setCodeUser: builder.mutation({
      query: ({ domainId, eventId, userId, code }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/codes`,
        method: 'PUT',
        body: { code },
      }),
      invalidatesTags: ['INVOICES'],
    }),
  }),
})

export const { useSetCodeUserMutation } = articleCodesApi
